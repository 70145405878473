import React from 'react';

import styles from './MainPage.module.css';
import IRan from '../asset/IRan.jpg';
import shoe from '../asset/shoe.png';
import gif from '../asset/gif.gif';
import horizontal from '../asset/horizontal_iran.jpeg';
import iranWithoutShoe from '../asset/iran_without_shoe.png';
import bg1 from '../asset/bg1.jpeg';
import bg2 from '../asset/bg2.jpeg';
import bg3 from '../asset/bg3.jpeg';
import iranFlag from '../asset/iran_flag.png';

const MainPage = () => {
    return (
        <div>
            <div className={styles['first-background-container']} style={{backgroundImage: `url(${bg1})`}}>
                <div className={styles['nav-bar-container']}>
                    <div className={styles['title']}>
                        <h1>iRan</h1>
                    </div>
                    <div className={styles['nav-bar']}>
                        <button className={styles['nav-bar-button']} onClick={() => window.open('https://example.com', '_blank')}>Twitter</button>
                        <button className={styles['nav-bar-button']} onClick={() => window.open('https://example.com', '_blank')}>Telegram</button>
                    </div>
                </div>
                <div className={styles['first-content-container']}>
                    <div className={styles['first-content-left']}>
                        <img src={shoe} alt='IRan' className={styles['first-content-left-img']} />
                    </div>
                    <div className={styles['first-content-right']}>
                        <div className={styles['first-content-left-title']}>
                            iRan
                        </div>
                        <div className={styles['first-content-left-text']}>
                            CA
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['second-background-container']} style={{backgroundImage: `url(${bg3})`}}>
                <div className={styles['second-content-top']}>
                    <div className={styles['second-content-top-left']}>
                        <div className={styles['second-content-top-left-img-container']}>
                            <img src={gif} alt='IRan' className={styles['second-content-top-left-img']} />
                            <div className={styles['img-text']}>Buy and be happy</div>
                        </div>
                    </div>
                    <div className={styles['second-content-top-right']}>
                        {/* png img */}
                        <img src={iranFlag} alt='IRan' className={styles['second-content-top-right-img']} />
                    </div>
                </div>
                <div className={styles['second-content-bottom']}>
                    <div className={styles['second-content-bottom-box-container']}>
                        <div className={styles['second-content-bottom-box-title']}>
                            {/* TITLE */}
                        </div>
                        <div className={styles['second-content-bottom-box-img-container']}>
                            <img src={horizontal} alt='IRan' className={styles['second-content-bottom-box-img']} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['third-background-container']} style={{backgroundImage: `url(${bg2})`}}>
                <h1>iRan, the pair we all need to march upon Israel</h1>
            </div>
        </div>
    );
};

export default MainPage;